import request from '@/utils/request'


// 查询事件列表
export function listEvent(query) {
  return request({
    url: '/event/event/list',
    method: 'get',
    params: query
  })
}

// 查询事件分页
export function pageEvent(query) {
  return request({
    url: '/event/event/page',
    method: 'get',
    params: query
  })
}

// 查询事件详细
export function getEvent(data) {
  return request({
    url: '/event/event/detail',
    method: 'get',
    params: data
  })
}

// 新增事件
export function addEvent(data) {
  return request({
    url: '/event/event/add',
    method: 'post',
    data: data
  })
}

// 修改事件
export function updateEvent(data) {
  return request({
    url: '/event/event/edit',
    method: 'post',
    data: data
  })
}

// 删除事件
export function delEvent(data) {
  return request({
    url: '/event/event/delete',
    method: 'post',
    data: data
  })
}
