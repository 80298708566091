<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="事件标题" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入事件标题" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="事件状态" prop="status">
                <a-select v-model="queryParam.status" placeholder="请选择事件状态">
                  <a-select-option :value="0">进行中</a-select-option>
                  <a-select-option :value="1">已完结</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="事件地区" prop="areaId">
                <a-cascader
                  ref="cascader"
                  v-model="areaInfo"
                  :options="options"
                  expand-trigger="hover"
                  placeholder="请选择地区"
                  :field-names="areaTreeProps"
                  @change="onchange"
                />
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">
              <a-form-item label="悬赏金额" prop="money">
                <a-input v-model="queryParam.money" placeholder="请输入悬赏金额" allow-clear/>
              </a-form-item>
            </a-col>-->
<!--            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="省份id" prop="provinceId">
                  <a-input v-model="queryParam.provinceId" placeholder="请输入省份id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="城市id" prop="cityId">
                  <a-input v-model="queryParam.cityId" placeholder="请输入城市id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="区域id" prop="areaId">
                  <a-input v-model="queryParam.areaId" placeholder="请输入区域id" allow-clear/>
                </a-form-item>
              </a-col>
&lt;!&ndash;              <a-col :md="8" :sm="24">
                <a-form-item label="浏览数量" prop="viewNum">
                  <a-input v-model="queryParam.viewNum" placeholder="请输入浏览数量" allow-clear/>
                </a-form-item>
              </a-col>&ndash;&gt;
            </template>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['event:event:add']">
          <a-icon type="plus" />新增
        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['event:event:edit']">
          <a-icon type="edit" />修改
        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['event:event:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['event:event:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['event:event:edit']" />
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['event:event:edit']">
            <a-icon type="edit" />修改
          </a>-->
          <a @click="$refs.createForm.handleInfo(record, undefined)" v-hasPermi="['event:event:query']">
            <a-icon type="info" />详情
          </a>
          <a-divider type="vertical" v-hasPermi="['event:event:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['event:event:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageEvent,listEvent, delEvent } from '@/api/event/event'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {cityListTree} from '@/api/logistics'

export default {
  name: 'Event',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      areaInfo: [],
      areaTreeProps: {
        value: 'areaId',
        label: 'areaName',
        children:'children'
      },
      options: [],
      total: 0,
      // 查询参数
      queryParam: {
        title: null,
        money: null,
        pic: null,
        content: null,
        provinceId: null,
        cityId: null,
        areaId: null,
        releaseType: null,
        status: null,
        viewNum: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '事件编号',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '事件标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '悬赏金额',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '相关图片地址,多个以逗号分隔',
          dataIndex: 'pic',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '事件介绍',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '地区',
          dataIndex: 'address',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '城市id',
          dataIndex: 'cityId',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '区域id',
          dataIndex: 'areaId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '发布方式',
          dataIndex: 'releaseType',
          ellipsis: true,
          align: 'center',
          customRender:function (text) {
            if (text === 0) {
              return "匿名发布";
            } else if (text === 1) {
              return "实名发布";
            } else {
              return "未知状态";
            }
          }
        },
        {
          title: '事件状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender:function (text) {
            if (text === 0) {
              return "进行中";
            } else if (text === 1) {
              return "已完结";
            } else {
              return "未知状态";
            }
          }
        },
        /*{
          title: '浏览数量',
          dataIndex: 'viewNum',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '发布时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getAreaList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onchange(val) {
      this.queryParam.areaId = val[val.length-1]
    },
    /** 查询地区列表 */
    getAreaList() {
      cityListTree().then(response => {
        this.options = this.formatData(response.data)
      })
    },
    formatData(data) {
      const that = this
      data.forEach((element) => {
        if (element.children && element.children.length > 0) {
          that.formatData(element.children)
        } else {
          element.children = []
        }
      })
      return data
    },
    /** 查询事件列表 */
    getList () {
      this.loading = true
     pageEvent(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        title: undefined,
        money: undefined,
        pic: undefined,
        content: undefined,
        provinceId: undefined,
        cityId: undefined,
        areaId: undefined,
        releaseType: undefined,
        status: undefined,
        viewNum: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.$refs.cascader.sValue = []
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delEvent(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('event/event/export', {
            ...that.queryParam
          }, `事件_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
